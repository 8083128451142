import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const htmlToExcel = {
  getExcel(dom, title = '默认标题') {
    const excelTitle = title;
    const element = document.getElementById(dom.replace('#', ''));
    const parentElement = element.cloneNode(true);
    let label = parentElement.querySelectorAll('label');
    label.forEach(item => item.remove());
    let input = parentElement.querySelectorAll('.el-input');
    input.forEach(item => item.remove());
    let select = parentElement.querySelectorAll('.el-select');
    select.forEach(item => item.remove());
    const wb = XLSX.utils.table_to_book(parentElement);
    /* 获取二进制字符串作为输出 */
    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
    try {
      FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), excelTitle + '.xlsx');
    } catch (e) {
      if (typeof console !== 'undefined') console.log(e, wbout);
    }
    return wbout;
  }
};

export default htmlToExcel;
